import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import JandK from "../../components/pages/jandk/jandk"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const JandKPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)
  const entries = content.entry

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='J&K' content={content.artists[7]} id={7}>
        <JandK entries={entries}/>
      </AccessWrapper>
    </Layout>
  )
}

export default JandKPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`