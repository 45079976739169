import React, { useEffect, useState } from "react"

import style from "./jandk.module.scss"
import AudioPlayer from '../../elements/audioPlayer'
import { EqualStencilFunc } from "three"

let allowNextVideo = true

const JandK = ({ entries }) => {

    const [ hoveredIndex , setHoveredIndex ] = useState(-2)

    // const handleOnEnded = () => {
    //     console.log('handle on ended', hoveredIndex)
    //     if(hoveredIndex < 2)  setHoveredIndex(hoveredIndex+1)
    //     else  setHoveredIndex(0)
    // }

    // // Make sure it doesnt stuck on a infinite loop

    // const handleOnPlay = (e) => {

    //     if(e.currentTarget.currentTime !== 0 || !allowNextVideo){ 
    //         // allowNextVideo =  true
    //         return false
    //     }

    //     allowNextVideo = false

    //     // When audio has ended, jump to next image
    //     if(hoveredIndex < 2)  setHoveredIndex(hoveredIndex+1)
    //     else  setHoveredIndex(0)
    //     console.log('handle on play', hoveredIndex)

    // }

    return(
        <div className={style.jandk}>
            <div>

            {
                hoveredIndex === -2 ?
                <div 
                className={style.info}  
                onClick={() => setHoveredIndex(hoveredIndex+1)}
                >
                    <div>
                        To experience Deep Contact Alex you need headphones and ideally a blanket to sit on (depending on the wether). Once at the site (indicated by the geolocation), choose one of the 3 objects (sand pit, sewer lid, tree) that you can see on the photos. Go to one of them, make yourself comfortable and settle into the spot. It is recommended to sit or lie down. Then start the audio piece that refers to the object you choose. Prepare to spend 20 minutes exploring each object.
                        <br/><br/>
                        Continue with Klick / Touch 
                    </div>
                </div>
                :
                <>
                    {
                        entries.map((entry, i) => (
                            <SingleEntry
                            key={i}
                            entry={entry}
                            index={i}
                            hoveredIndex={hoveredIndex}
                            setHoveredIndex={setHoveredIndex}
                            // handleOnPlay={handleOnPlay} 
                            // handleOnEnded={handleOnEnded}
                            />
                        ))
                    }
{/*                 
                    <img src={entries[hoveredIndex].image.filename}  onClick={handleOnEnded}/>
                    <div className={style.audio}>
                        <AudioPlayer
                        color={"red"}
                        file={entries[hoveredIndex].audio.filename}
                        onEnded={handleOnEnded}
                        onPlay={handleOnPlay}
                        autoPlay={true}
                        />
                    </div> */}
                </>
            }
            <div className={style.overviewButton}>
                <div  onClick={() => setHoveredIndex(-2)}>Show Text</div>
                <div  onClick={() => setHoveredIndex(-1)}>Overview</div>
            </div>
            </div>
        </div>
    )
}

export default JandK


const SingleEntry = ({ entry, index, hoveredIndex, setHoveredIndex }) => {


    const handleOnEnded = () => {
        console.log('handle on ended', hoveredIndex)

        if(hoveredIndex === -1){ 
            console.log('select image from overview')
            setHoveredIndex(index)
            return;
        
        }

        if(hoveredIndex < 2)  setHoveredIndex(hoveredIndex+1)
        else  setHoveredIndex(0)
    }
    // Make sure it doesnt stuck on a infinite loop

    const handleOnPlay = (e) => {

        if(e.currentTarget.currentTime !== 0 || !allowNextVideo){ 
            // allowNextVideo =  true
            return false
        }

        allowNextVideo = false

        // When audio has ended, jump to next image
        if(hoveredIndex < 2)  setHoveredIndex(hoveredIndex+1)
        else  setHoveredIndex(0)
        console.log('handle on play', hoveredIndex)
    }

    let className = ''
    const isActive = index === hoveredIndex

    if(isActive) className = style.isActive
    else if(hoveredIndex >= 0) className = style.isInActive

    return (
        <>
            <img src={entry.image.filename}  onClick={handleOnEnded} className={className}/>
            <div className={style.audio}>
                {isActive && <AudioPlayer
                color={"red"}
                file={entry.audio.filename}
                onEnded={handleOnEnded}
                onPlay={handleOnPlay}
                autoPlay={true}
                />}
            </div>
        </>

    )
}